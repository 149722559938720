<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 245 204"
    aria-hidden="true"
  >
    <path
      d="M96.1,52.817c1.429-20.384-10.992-40.992-29.536-49C47.362-4.478,23.9,1.11,10.768,17.1-2.879,33.72-3.644,58.486,8.988,74.7a59.957,59.957,0,0,0,4.808,5.29c2.039,2.064,4.147,4.2,4.66,5.777.686,2.123.333,5.146-.076,8.647a56.8,56.8,0,0,0-.547,9.63C18.6,119.242,31,132.8,46.068,134.907a31.838,31.838,0,0,0,4.433.306c14.509,0,29.95-9.612,33.323-27.681l-18.159-3.389C63.7,114.69,54.181,117.381,48.631,116.611c-6.367-.892-12.023-7.073-12.347-13.5a41.914,41.914,0,0,1,.444-6.551c.578-4.935,1.233-10.528-.7-16.491-1.862-5.742-5.866-9.8-9.085-13.058a45.7,45.7,0,0,1-3.381-3.661c-7.211-9.26-6.547-24.751,1.483-34.531,7.755-9.444,22.772-12.984,34.193-8.049,11.393,4.921,19.317,18.14,18.431,30.75Z"
      transform="translate(0.328 82.602) rotate(-31)"
      fill="currentColor"
    />
    <rect
      width="18.475"
      height="22.52"
      transform="translate(-6.768 120.639) rotate(-101.156)"
      fill="currentColor"
    />
    <rect
      width="18.473"
      height="22.456"
      transform="matrix(0.27, -0.963, 0.963, 0.27, -8.625, 86.324)"
      fill="currentColor"
    />
    <rect
      width="18.475"
      height="21.361"
      transform="translate(5.646 53.834) rotate(-43.958)"
      fill="currentColor"
    />
    <path
      id="Path_3114"
      data-name="Path 3114"
      d="M41.313,46.661a9.235,9.235,0,0,0,9.574-9.449l-.016-.737c-.2-8.606-.446-19.318-7.424-27.545C36.031.19,23.482-2.51,13.608,2.508,5.65,6.548.563,15.207,0,25.669l18.446.993c.2-3.736,1.554-6.682,3.53-7.684,2.293-1.168,5.471-.351,7.382,1.9,1.694,2,2.4,5.345,2.73,9.024A20.4,20.4,0,0,0,21.215,40.241a22.283,22.283,0,0,0,3.628,23.806,23.83,23.83,0,0,0,17.7,7.78,23.382,23.382,0,0,0,4.381-.412L43.443,53.271A5.339,5.339,0,0,1,38.679,51.8a3.9,3.9,0,0,1-.7-3.792C38.6,46.668,40.683,46.643,41.313,46.661Z"
      transform="translate(32.779 91.248) rotate(-31)"
      fill="currentColor"
    />
    <path
      id="Path_3112"
      data-name="Path 3112"
      d="M151.875,67C150.12,68.335,108.4,99.661,66.768,89.765S1.965,33.13,1,31.145A9.9,9.9,0,0,1,4.625,18.436C6.036,17.546,34.335,0,67.62,0A86.088,86.088,0,0,1,87.558,2.271c41.123,9.772,65.768,50.035,66.8,51.744A9.9,9.9,0,0,1,151.875,67ZM78.864,34.348a11.459,11.459,0,0,0-1.827,22.845c.311.024.623.037.932.037a11.465,11.465,0,0,0,11.4-10.546v0A11.471,11.471,0,0,0,78.864,34.348ZM50.41,60.48a31.1,31.1,0,0,1,9.626-40.271c-14.865,1.433-28.253,6.652-36.453,10.54C28.756,38.9,37.963,51.415,50.41,60.48Zm55.443-28.7a31.058,31.058,0,0,1-8.909,38.758c14.26-2.8,27.112-9.371,34.961-14.069A113.725,113.725,0,0,0,105.854,31.779Z"
      transform="matrix(-0.999, -0.035, 0.035, -0.999, 279.225, 161.516)"
      fill="currentColor"
    />
    <rect
      width="27.365"
      height="19.8"
      transform="matrix(-0.148, -0.989, 0.989, -0.148, 166.211, 62.141)"
      fill="currentColor"
    />
    <rect
      width="25.906"
      height="19.799"
      transform="translate(129.807 77.027) rotate(-116.929)"
      fill="currentColor"
    />
    <rect
      width="19.801"
      height="26.915"
      transform="matrix(-0.961, -0.275, 0.275, -0.961, 227.074, 64.953)"
      fill="currentColor"
    />
  </svg>
</template>
